document.addEventListener("DOMContentLoaded", function () {
    const navbarCartShop = document.getElementById("navbarCartShop");
    const navbarBurger = document.getElementById("navbarBurger");
    const navbarCart = document.getElementById("navbarCart");
    const navbarMenu = document.getElementById("navbarMenu");
    const cartShopClose = document.getElementById("cartShopClose");



    navbarBurger.addEventListener("click", function () {
        navbarMenu.classList.toggle("active");
    });

    navbarCart.addEventListener("click", function(){
        navbarCartShop.classList.toggle("active");
    });

    cartShopClose.addEventListener("click", function(){
        navbarCartShop.classList.toggle("active");
    });
});